import React from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { MdAnchor } from "react-icons/md";

function BookNowCard({
  cardImage,
  cardTitle,
  cardContent,
  cardContent2,
  cardLink,
}) {
  return (
    <>
      <div
        className="rounded-2"
        style={{ boxShadow: "0 3px 10px #222", border: "1px solid #777" }}
      >
        <div className="overflow-hidden">
          <Link to={cardLink}>
            <img
              src={cardImage}
              alt="card"
              className="w-100 rounded-top-2 imgHover"
            />
          </Link>
        </div>
        <div className="mt-4 mx-2">
          <h3 className="mb-3">{cardTitle}</h3>
          <p>{cardContent}</p>
          <p style={{ fontSize: ".9rem" }}>{cardContent2}</p>
          <Button className="mb-3 buttonHover" href={cardLink}>
            <MdAnchor /> BOOK ONLINE NOW!
          </Button>
        </div>
      </div>
    </>
  );
}

export default BookNowCard;
