import { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import emailjs from "@emailjs/browser";

function Contact() {
  const form = useRef();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [about, setAbout] = useState("");
  const [errorName, setErrorName] = useState("formInput");
  const [errorEmail, setErrorEmail] = useState("formInput");
  const [errorPhone, setErrorPhone] = useState("formInput");
  const [errorAbout, setErrorAbout] = useState("formInput");
  const [waitMessage, setWaitMessage] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    !name && setErrorName("errorFormInput");
    !email && setErrorEmail("errorFormInput");
    !phone && setErrorPhone("errorFormInput");
    !about && setErrorAbout("errorFormInput");

    if (name && email && phone && about) {
      setWaitMessage("Please wait...");

      emailjs
        .sendForm(
          "service_70dkx5g",
          "template_pjmjuy5",
          form.current,
          "lIvuQcS29W-1LzLJc"
        )
        .then(
          function (response) {
            setName("");
            setEmail("");
            setPhone("");
            setAbout("");
            setWaitMessage("Your message has been sent.");
            console.log("SUCCESS!", response.status, response.text);
          },
          function (err) {
            console.log("FAILED...", err);
            setWaitMessage(
              "There was an error. Please reload the page and try again."
            );
          }
        );
    } else {
      setWaitMessage("Required field(s) missing. Please check form.");
    }
  };
  return (
    <>
      <div className="pageFrame p-3 mb-3">
        <div className="text-center">
          <h1 style={{ color: "#13a8da" }} className="display-5 d-inline">
            Questions?{" "}
          </h1>
          <h1 style={{ color: "#1e2e3d" }} className="display-5 d-inline">
            Contact Us
          </h1>
        </div>
        {/* <p className="fs-5 mb-0 mt-3 px-0 px-lg-5">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat.
        </p> */}
      </div>
      <div className="pageFrame p-3 mt-0 mb-3">
        <div className="container-lg p-0">
          <div className="row justify-content-center">
            <div className="col-12 col-md-9 col-lg-8 col-xl-7 col-xxl-6 text-center">
              <h3 className="quoteFormHeader text-white">
                <strong>CONTACT INFORMATION</strong>
              </h3>
              <div className="quoteForm">
                <form ref={form} onSubmit={onSubmit}>
                  <div className="container-fluid">
                    <div className="row justify-content-center pt-4">
                      <div className="col-6">
                        <input
                          className={errorName}
                          value={name}
                          name="name"
                          type="text"
                          placeholder="Full Name"
                          onChange={(e) => {
                            setName(e.target.value);
                            setErrorName("formInput");
                          }}
                        />
                      </div>
                      <div className="col-6">
                        <input
                          className={errorEmail}
                          value={email}
                          name="email"
                          type="email"
                          placeholder="Email Address"
                          onChange={(e) => {
                            setEmail(e.target.value);
                            setErrorEmail("formInput");
                          }}
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-6">
                        <input
                          className={errorPhone}
                          value={phone}
                          name="phone"
                          type="text"
                          placeholder="Phone Number"
                          onChange={(e) => {
                            setPhone(e.target.value);
                            setErrorPhone("formInput");
                          }}
                        />
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-12">
                        <textarea
                          rows="8"
                          className={errorAbout}
                          value={about}
                          name="about"
                          placeholder="Your message"
                          onChange={(e) => {
                            setAbout(e.target.value);
                            setErrorAbout("formInput");
                          }}
                        />
                      </div>
                    </div>
                    {waitMessage ? (
                      <p className="waitMessage">
                        <em>{waitMessage}</em>
                      </p>
                    ) : (
                      ""
                    )}
                    <Button className="mb-3" variant="dark" type="submit">
                      Send
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
