import React from "react";
import BookNowCard from "../components/BookNowCard";
import card1 from "../images/tour1.jpg";
import card2 from "../images/tour2.jpg";
import card3 from "../images/tour3.jpg";
import card4 from "../images/tour4.jpg";
import card5 from "../images/tour5.jpg";

function Book() {
  return (
    <>
      <div className="pageFrame p-3 mb-3">
        <div className="text-center">
          <h1 style={{ color: "#13a8da" }} className="display-5 d-inline">
            Our Most{" "}
          </h1>
          <h1 style={{ color: "#1e2e3d" }} className="display-5 d-inline">
            Popular Charters
          </h1>
        </div>
        <p className="fs-5 mb-4 mt-3 px-0 px-lg-5">
          {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. */}
        </p>
        <div className="row justify-content-center">
          <div className="col-12 col-md-6 col-xl-4 mb-4">
            <BookNowCard
              cardImage={card1}
              cardTitle="Choose Your Own Charter!"
              cardContent="$2,997 for 3 Hours • Best for ages 5 and up • Private Luxury Catamaran • Up to 6 passengers"
              cardContent2="Luxury catamaran customizable cruise!"
              cardLink="https://fareharbor.com/embeds/book/chasing-waterfalls/items/465341/?full-items=yes&flow=966403"
            />
          </div>
          <div className="col-12 col-md-6 col-xl-4 mb-4">
            <BookNowCard
              cardImage={card2}
              cardTitle="Private Sailing Lesson"
              cardContent="5 Hours • Best for ages 5 and up • Private Luxury Catamaran • Up to 6 passengers"
              cardContent2="Learn the Ropes!"
              cardLink="https://fareharbor.com/embeds/book/chasing-waterfalls/items/465385/?full-items=yes&flow=966403"
            />
          </div>
          <div className="col-12 col-md-6 col-xl-4 mb-4">
            <BookNowCard
              cardImage={card3}
              cardTitle="All Day Charter!"
              cardContent="8 Hours • Best for ages 5 and up • Private Luxury Catamaran • Up to 6 passengers"
              cardContent2="Luxury catamaran customizable cruise!"
              cardLink="https://fareharbor.com/embeds/book/chasing-waterfalls/items/465398/?full-items=yes&flow=966403"
            />
          </div>
          <div className="col-12 col-md-6 col-xl-4">
            <BookNowCard
              cardImage={card4}
              cardTitle="Week Long Charter!"
              cardContent="Call to Book! • 7 Days • Great for small groups and families • Up to 6 passengers"
              cardContent2="Luxury catamaran customizable cruise!"
              cardLink="https://fareharbor.com/embeds/book/chasing-waterfalls/items/465410/?full-items=yes&flow=966403"
            />
          </div>
          <div className="col-12 col-md-6 col-xl-4">
            <BookNowCard
              cardImage={card5}
              cardTitle="Gift Card"
              cardContent="Give them an experience they'll remember!"
              cardContent2="Gift Cards Valid for any of our offerings!"
              cardLink="https://fareharbor.com/embeds/book/chasing-waterfalls/items/465468/?full-items=yes&flow=966403"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Book;
