import ReactPlayer from "react-player/youtube";

function Video({ youtube }) {
  return (
    <div style={{ position: "relative", paddingTop: "56.25%" }}>
      <ReactPlayer
        style={{ position: "absolute", top: "0", left: "0" }}
        url={`https://youtu.be/${youtube}`}
        playing={true}
        loop={true}
        volume={0}
        muted={true}
        width="100%"
        height="100%"
      />
    </div>
  );
}

export default Video;
