import React from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { MdAnchor } from "react-icons/md";

function ChartersCards({
  title1,
  title2,
  blurb,
  image1,
  image2,
  image3,
  paragraph,
  classActive,
}) {
  return (
    <div className={`pageFrame p-3 mt-0 mb-3 charterSection ${classActive}`}>
      <div className="text-center">
        <h1 style={{ color: "#13a8da" }} className="display-5 d-inline">
          {title1}{" "}
        </h1>
        <h1 style={{ color: "#1e2e3d" }} className="display-5 d-inline">
          {title2}
        </h1>
        <h3 className="mt-3 mb-4 px-0 px-lg-5">{blurb}</h3>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-4 mb-3 mb-md-0">
          <img src={image1} alt={image1} className="w-100 rounded-2" />
        </div>
        <div className="col-12 col-md-4 mb-3 mb-md-0">
          <img src={image2} alt={image2} className="w-100 rounded-2" />
        </div>
        <div className="col-12 col-md-4">
          <img src={image3} alt={image3} className="w-100 rounded-2" />
        </div>
      </div>
      {/* <div className="mt-4 fs-5 px-0 px-lg-5">
        <p className="mb-0">{paragraph}</p>
      </div> */}
      <div className="text-center">
        <Button
          style={{
            border: "2px solid white",
            borderRadius: "25px",
            boxShadow: "0 3px 5px black",
          }}
          as={Link}
          to="/book"
          size="lg"
          className="mt-4"
        >
          <MdAnchor /> BOOK NOW
        </Button>
      </div>
    </div>
  );
}

export default ChartersCards;
