import React from "react";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { MdAnchor } from "react-icons/md";
import family from "../images/family.jpg";
import crew1 from "../images/crew1.jpg";
import crew2 from "../images/crew2.jpg";
import crew3 from "../images/crew3.jpg";

function About() {
  return (
    <>
      <div className="pageFrame p-3 mb-3">
        <div className="text-center">
          <h1 style={{ color: "#13a8da" }} className="display-5 d-inline">
            About{" "}
          </h1>
          <h1 style={{ color: "#1e2e3d" }} className="display-5 d-inline">
            Us
          </h1>
        </div>
        <div className="fs-5 mb-0 mt-3 px-0 px-lg-5">
          <img
            src={family}
            alt="about"
            className="mt-2 me-3 mb-3 rounded-2"
            style={{ width: "45%", maxWidth: "400px", float: "left" }}
          />
          Steven Bonner, Owner
          <div style={{ height: "1rem" }} />
          In 2017, Capt. Bonner set out with his family from Ft. Lauderdale, FL
          on a 7-year circumnavigation with his family. Covid closed all borders
          in 2020 so continuing was not possible. After sailing from French
          Polynesia to Honolulu on this magical luxury 52' world cruising
          catamaran, the Bonner Family dropped anchor permanently in Hawaii and
          converted this live-aboard world cruiser to the finest charter boat in
          the Hawaiian Islands.
          <div style={{ height: "1rem" }} />
          Capt. Bonner has parlayed his restauranteur and culinary background in
          this venture as well, offering amazing cuisine paired with wines
          bringing joy to the most discerning palates.
          <div style={{ height: "1rem" }} />
          When Capt. Bonner is not sailing, or entertaining friends and family,
          or putting together the perfect charter for his clients, he focuses
          his time on his insurance agency where he provides all lines of
          insurance but specializes in marine insurance.
          <div style={{ height: "1rem" }} />
          Capt. Bonner also serves at the Hawaii Yacht Club, as Rear Commodore
          for Power and is always ready to help his world-traveling fellow
          sailors.
        </div>
      </div>
      <div className="pageFrame p-3 mt-0 mb-3" style={{ minHeight: "264px" }}>
        <div className="text-center mb-4">
          <h1 style={{ color: "#13a8da" }} className="display-5 d-inline">
            Our{" "}
          </h1>
          <h1 style={{ color: "#1e2e3d" }} className="display-5 d-inline">
            Crew
          </h1>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-4 mb-3 mb-md-0">
            <img src={crew1} alt="crew1" className="w-100 rounded-2" />
            <h3 className="mt-3 text-center mb-0">Sydney Corbin</h3>
            <p className="fs-5 mt-3 mb-3 mb-md-0 px-0 px-md-3">
              Sydney has spent 6 years in hospitality where she played an
              intricate role in progressing from a server/bartender to a
              manager. However, with a strong sports background & outgoing
              personality she was attracted to a career that would enable her to
              make the most of her interpersonal skills & enjoyment of water
              sports. Drawing on her skills gained from the service industry,
              she displays a high level of attention to detail & organization,
              essential attributes for her role as a stewardess. Sydney is a
              professional, efficient, & positive member of the crew,
              instrumental to the smooth running of the interior and exterior.
              Sydney's interests include water sports such as surfing and
              diving. When on dry land she enjoys boxing, hiking, running, and
              motocross!
            </p>
          </div>
          <div className="col-12 col-md-4 mb-3 mb-md-0">
            <img src={crew3} alt="crew2" className="w-100 rounded-2" />
            <h3 className="mt-3 text-center mb-0">Zachary Moose</h3>
            <p className="fs-5 mt-3 mb-3 mb-md-0 px-0 px-md-3">
              I started traveling at the age of 18, and see no signs of slowing
              down. I've been fortunate enough to be able to backpack, surf,
              sail, and live across the planet for the past 17 years. I have had
              many different occupations throughout my adventures including
              everything from a farmer, tour guide, and lifeguard to nearly
              every field of construction, but from the first time I stepped
              foot on a boat I knew that I had found what I truly wanted to do
              with my life. Over the past decade I've crewed, Captained,
              delivered, chartered, and rebuilt a multitude of different vessels
              around the world. I currently hold a U.S.C.G. Master 100 Ton
              License with Sailing Endorsement. Also C.P.R. / First Aid &
              Secondary Care, Crowd Management, Crisis Management, P.A.D.I.
              certified, and S.T.C.W.
            </p>
          </div>
          <div className="col-12 col-md-4">
            <img src={crew2} alt="crew3" className="w-100 rounded-2" />
            {/* <p className="fs-5 mt-3 mb-0 px-0 px-md-3">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur.
            </p> */}
          </div>
        </div>
        <div className="text-center mt-5">
          <Button
            style={{
              border: "2px solid white",
              borderRadius: "25px",
              boxShadow: "0 3px 5px black",
            }}
            as={Link}
            to="/book"
            size="lg"
          >
            <MdAnchor /> BOOK NOW
          </Button>
        </div>
      </div>
    </>
  );
}

export default About;
