import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import logo from "../images/logo-H100px.jpg";
import bigLogo from "../images/bigLogo.png";
import Button from "react-bootstrap/Button";
import { MdAnchor } from "react-icons/md";

function Header() {
  const [show, setShow] = useState(false);

  const ref = useRef(null);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <>
      <div className="py-0 header position-fixed z-0 ms-4">
        <Link to="/" className="me-0 headerLink">
          <img
            src={bigLogo}
            alt="logo"
            className="w-auto bigLogo"
            style={{ height: "8rem" }}
          />
        </Link>
        <Link className="headerLink" to="/">
          Home
        </Link>
        <Link className="headerLink" to="/explore">
          Explore
        </Link>
        <Link className="headerLink" to="/charters">
          Charters
        </Link>
        <Link className="headerLink" to="/about">
          About Us
        </Link>
        <Link className="headerLink" to="/contact">
          Contact Us
        </Link>
        <Button
          style={{
            border: "2px solid white",
            borderRadius: "25px",
            boxShadow: "0 3px 5px black",
          }}
          as={Link}
          to="/book"
          size="lg"
          className="mt-3"
        >
          <MdAnchor /> BOOK NOW
        </Button>
      </div>

      <Navbar
        bg="light"
        variant="light"
        expand="lg"
        className="py-0 header-mobile top-0"
        expanded={show}
        ref={ref}
      >
        <Container fluid>
          <Navbar.Brand as={Link} to="/" className="me-0">
            <img
              src={logo}
              alt="logo"
              className="logo w-auto"
              style={{ height: "2rem" }}
            />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="offcanvasNavbar"
            onClick={() => setShow(!show)}
          />
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbar"
            placement="end"
          >
            <Offcanvas.Header closeButton onClick={() => setShow(false)}>
              <Offcanvas.Title>
                <Button
                  style={{
                    border: "2px solid white",
                    borderRadius: "25px",
                    boxShadow: "0 3px 5px black",
                  }}
                  as={Link}
                  to="/book"
                  size="lg"
                >
                  <MdAnchor /> BOOK NOW
                </Button>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body
              className="text-center"
              style={{ marginTop: "2rem" }}
            >
              <Nav>
                <Nav.Link as={Link} onClick={() => setShow(false)} to="/">
                  Home
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  onClick={() => setShow(false)}
                  to="/explore"
                >
                  Explore
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  onClick={() => setShow(false)}
                  to="/charters"
                >
                  Charters
                </Nav.Link>
                <Nav.Link as={Link} onClick={() => setShow(false)} to="/about">
                  About Us
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  onClick={() => setShow(false)}
                  to="/contact"
                >
                  Contact Us
                </Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
