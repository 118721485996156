import React, { useState } from "react";
import sunset1 from "../images/sunset1.jpg";
import sunset2 from "../images/sunset2.jpg";
import sunset3 from "../images/sunset3.jpg";
import ChartersCards from "../components/ChartersCards";
import snorkel1 from "../images/snorkel1.jpg";
import snorkel2 from "../images/snorkel2.jpg";
import snorkel3 from "../images/snorkel3.jpg";
import food1 from "../images/food1.jpg";
import food2 from "../images/food2.jpg";
import food3 from "../images/food3.jpg";
import fishing1 from "../images/fishing1.jpg";
import fishing2 from "../images/fishing2.jpg";
import fishing3 from "../images/fishing3.jpg";
import family1 from "../images/family1.jpg";
import family2 from "../images/family2.jpg";
import family3 from "../images/family3.jpg";
import bachelor1 from "../images/bachelor1.jpg";
import bachelor2 from "../images/bachelor2.jpg";
import bachelor3 from "../images/bachelor3.jpg";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { MdAnchor } from "react-icons/md";
import charter1 from "../images/surf.jpg";
import charter2 from "../images/charters2.jpg";
import charter3 from "../images/charters3.jpg";

function Charters() {
  const [active, setActive] = useState("active");
  const [title1, setTitle1] = useState("Sunset");
  const [title2, setTitle2] = useState("Charter");
  const [blurb, setBlurb] = useState(
    "Enjoy a sunset cruise aboard the beautiful Chasing Waterfalls luxury yacht..."
  );
  const [image1, setImage1] = useState(sunset1);
  const [image2, setImage2] = useState(sunset2);
  const [image3, setImage3] = useState(sunset3);
  const [paragraph, setParagraph] = useState(
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
  );

  function handleClick(
    title1Click,
    title2Click,
    blurbClick,
    image1Click,
    image2Click,
    image3Click,
    paragraphClick
  ) {
    setActive("");
    setTimeout(() => {
      setTitle1(title1Click);
      setTitle2(title2Click);
      setBlurb(blurbClick);
      setImage1(image1Click);
      setImage2(image2Click);
      setImage3(image3Click);
      setParagraph(paragraphClick);
      setActive("active");
    }, 250);
  }

  return (
    <>
      <div className="pageFrame p-3 mb-3 text-center">
        <div className="text-center">
          <h1 style={{ color: "#13a8da" }} className="display-5 d-inline">
            Our Charter{" "}
          </h1>
          <h1 style={{ color: "#1e2e3d" }} className="display-5 d-inline">
            Packages
          </h1>
        </div>
        <h3 className="mt-3 mb-3 px-0 px-lg-5">
          {" "}
          Explore our different Charter Packages. Your imagination is the limit!
        </h3>
        <div>
          <Button
            variant="link"
            className="fs-4 d-inline-block"
            onClick={() =>
              handleClick(
                "Sunset",
                "Charter",
                "Enjoy a sunset cruise aboard the beautiful Chasing Waterfalls luxury yacht...",
                sunset1,
                sunset2,
                sunset3,
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veni nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
              )
            }
          >
            Sunset Charter
          </Button>
          <Button
            variant="link"
            className="fs-4 d-inline-block"
            onClick={() =>
              handleClick(
                "Snorkel",
                "Charter",
                "Spend the day snorkeling, observing local wildlife, and appreciating the wonders of the ocean...",
                snorkel1,
                snorkel2,
                snorkel3,
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex nsequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
              )
            }
          >
            Snorkel Charter
          </Button>
          <Button
            variant="link"
            className="fs-4 d-inline-block"
            onClick={() =>
              handleClick(
                "Wine/Cocktail",
                "Charter",
                "Enjoy wine, drinks, and cocktails while crusing the ocean, along with pupus and food pairings. Sommelier also available...",
                food1,
                food2,
                food3,
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
              )
            }
          >
            Wine/Cocktail
          </Button>
          <Button
            variant="link"
            className="fs-4 d-inline-block"
            onClick={() =>
              handleClick(
                "Family",
                "Charters",
                "Spend the day with your family sailing aboard Chasing the Waterfalls yacht. Snorkel, paddleboard, swim, and more...",
                family1,
                family2,
                family3,
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit incepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
              )
            }
          >
            Family Charter
          </Button>
          <Button
            variant="link"
            className="fs-4 d-inline-block"
            onClick={() =>
              handleClick(
                "Bachelor/Bachelorette",
                "Charters",
                "Celebrate your Bachelor or Bachelorette party in style, sailing on the ocean aboard the Chasing Waterfalls luxury yacht...",
                bachelor1,
                bachelor2,
                bachelor3,
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim vtate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
              )
            }
          >
            Bachelor/Bachelorette
          </Button>
          <Button
            variant="link"
            className="fs-4 d-inline-block"
            onClick={() =>
              handleClick(
                "Fishing",
                "Charters",
                "Catch ulua, papio, and maybe even ahi while trolling the waters of Oahu aboard the Chasing Waterfalls yacht...",
                fishing1,
                fishing2,
                fishing3,
                "Lorem ipsum dolo nsectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
              )
            }
          >
            Fishing Charter
          </Button>
        </div>
      </div>
      <ChartersCards
        title1={title1}
        title2={title2}
        blurb={blurb}
        image1={image1}
        image2={image2}
        image3={image3}
        paragraph={paragraph}
        classActive={active}
      />
      <div className="pageFrame p-3 mt-0 mb-3">
        <div className="text-center">
          <h1 style={{ color: "#13a8da" }} className="display-5 d-inline">
            We Also{" "}
          </h1>
          <h1 style={{ color: "#1e2e3d" }} className="display-5 d-inline">
            Provide
          </h1>
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 col-lg-6">
              <ul className="mt-3 mb-4 fs-5 text-start">
                <li>Diving Charters</li>
                <li>
                  Learn to Sail Charters: Let our team of experienced qualified
                  sailors teach you to sail, from the basics to Bluewater World
                  Cruising
                </li>
                <li>Movie Production Charters</li>
                <li>Sand Bar Charters</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-4 mb-3 mb-md-0">
            <img src={charter2} alt="charter" className="w-100 rounded-2" />
          </div>
          <div className="col-12 col-md-4 mb-3 mb-md-0">
            <img src={charter3} alt="charter2" className="w-100 rounded-2" />
          </div>
          <div className="col-12 col-md-4">
            <img src={charter1} alt="charter3" className="w-100 rounded-2" />
          </div>
        </div>
        <div className="text-center mt-4">
          <h1 style={{ color: "#13a8da" }} className="display-5 d-inline">
            Multiple Night{" "}
          </h1>
          <h1 style={{ color: "#1e2e3d" }} className="display-5 d-inline">
            Charters
          </h1>
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 d-flex justify-content-center">
              <ul className="mt-3 mb-4 fs-5 text-start">
                <li>Overnight to Week-Long Charters</li>
                <li>Explore Hawaii - Inner Island Charters</li>
              </ul>
            </div>
            <div className="text-center">
              <h3>ANYTHING IS POSSIBLE WITH CHASING WATERFALLS</h3>
            </div>
          </div>
        </div>
        <div className="text-center mt-4">
          <h1 style={{ color: "#13a8da" }} className="display-5 d-inline">
            Options{" "}
          </h1>
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 d-flex justify-content-center">
              <ul className="mt-3 mb-0 fs-5 text-start">
                <li>Gourmet Chef with pairings from Sommelier</li>
                <li>
                  Photography - Simple to extreme. Also Drone video and
                  photography available
                </li>
                <li>
                  Hydro Foil equipment, training brought to the yacht at anchor
                </li>
                <li>
                  Tons of toys, kayaks, wind surf, kite surfing, surf boards,
                  etc.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="text-center">
          <Button
            style={{
              border: "2px solid white",
              borderRadius: "25px",
              boxShadow: "0 3px 5px black",
            }}
            as={Link}
            to="/book"
            size="lg"
            className="mt-4"
          >
            <MdAnchor /> BOOK NOW
          </Button>
        </div>
      </div>
    </>
  );
}

export default Charters;
