import React, { useRef, useEffect } from "react";
import { Carousel as NativeCarousel } from "@fancyapps/ui";
import { Thumbs } from "@fancyapps/ui/dist/carousel/carousel.thumbs.esm.js";
import "@fancyapps/ui/dist/carousel/carousel.css";
import "@fancyapps/ui/dist/carousel/carousel.thumbs.css";
import FancyBox from "../components/FancyBoxComp";

function FancyCarousel(props) {
  props.slides.sort(() => {
    return 0.5 - Math.random();
  });

  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const options = props.options || {
      Dots: false,
      transition: "slide",
      Thumbs: {
        type: "classic",
      },
    };

    const instance = new NativeCarousel(container, options, { Thumbs });

    return () => {
      instance.destroy();
    };
  });

  return (
    <FancyBox>
      <div className="carouselContainer">
        <div className="myCarousel" ref={containerRef}>
          {props.slides.map((image) => {
            return (
              <div
                key={image.id}
                className="f-carousel__slide d-flex justify-content-center align-items-center"
                data-thumb-src={image.image}
                data-fancybox
                data-src={image.image}
              >
                <img
                  style={{
                    border: "4px solid #333",
                    borderRadius: "5px",
                  }}
                  className="h-100"
                  alt=""
                  data-lazy-src={image.image}
                />
              </div>
            );
          })}
        </div>
      </div>
    </FancyBox>
  );
}

export default FancyCarousel;
