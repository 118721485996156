import React from "react";
import { BiCopyright } from "react-icons/bi";
import { Link } from "react-router-dom";
import footerLogo from "../images/footerLogo.png";
import { FaPhoneAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import Button from "react-bootstrap/Button";
import { MdAnchor } from "react-icons/md";

function Footer() {
  return (
    <>
      <div style={{ height: "1rem" }}></div>
      <div className="footer pt-5 pb-4">
        <div className="container">
          <div className="row justify-content-center fs-5">
            <div className="col-10 order-2 order-md-1 col-md-4 col-lg-4 d-flex justify-content-md-center justify-content-start">
              <div>
                <p>
                  <Link to="/">Home</Link>
                </p>
                <p>
                  <Link to="/explore">Explore</Link>
                </p>
                <p>
                  <Link to="/charters">Charters</Link>
                </p>
                <p>
                  <Link to="/about">About Us</Link>
                </p>
              </div>
            </div>
            <div className="col-7 order-1 order-md-2 col-md-4 col-lg-4 col-xl-3 px-xxl-4 d-flex justify-content-center mb-3">
              <Link to="/">
                <img src={footerLogo} alt="footer" className="w-100" />
              </Link>
            </div>
            <div className="col-10 order-3 col-md-4 col-lg-4 d-flex justify-content-md-center  justify-content-start">
              <div>
                <p>
                  <Link to="/contact">Contact Us</Link>
                </p>
                <p>
                  <a href="tel:8087724900">
                    <FaPhoneAlt />{" "}
                    <span style={{ fontSize: "1rem" }}>808-772-4900</span>
                  </a>
                </p>
                <p>
                  <a href="mailto:steve@chasing-waterfalls.com">
                    <MdEmail />{" "}
                    <span style={{ fontSize: "1rem" }}>
                      steve@chasing-waterfalls.com
                    </span>
                  </a>
                </p>
                <Button
                  style={{
                    border: "2px solid white",
                    borderRadius: "25px",
                    boxShadow: "0 3px 5px black",
                  }}
                  as={Link}
                  to="/book"
                  className="mt-2 mb-4 mb-md-0"
                >
                  <MdAnchor /> BOOK NOW
                </Button>
              </div>
            </div>
          </div>
        </div>
        <p className="text-center mt-4 mb-0">
          Chasing Waterfalls <BiCopyright /> 2023
        </p>
      </div>
    </>
  );
}

export default Footer;
