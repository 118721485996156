import React from "react";
import slide1 from "../images/explore (1).jpg";
import slide2 from "../images/explore (2).jpg";
import slide3 from "../images/explore (3).jpg";
import slide4 from "../images/isara.jpg";
import slide5 from "../images/explore (5).jpg";
import slide6 from "../images/explore (6).jpg";
import slide7 from "../images/explore (7).jpg";
import slide8 from "../images/explore (8).jpg";
import slide9 from "../images/explore (9).jpg";
import slide10 from "../images/explore (10).jpg";
import slide11 from "../images/explore (11).jpg";
import slide12 from "../images/explore (12).jpg";
import slide13 from "../images/explore (13).jpg";
import slide14 from "../images/explore (14).jpg";
import slide15 from "../images/explore (15).jpg";
import slide16 from "../images/explore (16).jpg";
import slide17 from "../images/explore (17).jpg";
import slide18 from "../images/explore (18).jpg";
import slide19 from "../images/card1.jpg";
import slide20 from "../images/card2.jpeg";
import slide21 from "../images/cockpit.jpeg";
import slide22 from "../images/stern.jpg";
import slide23 from "../images/bed1.jpg";
import slide24 from "../images/bed2.jpg";
import slide25 from "../images/tv.jpg";
import slide26 from "../images/kitchen.jpg";
import slide27 from "../images/dining.jpg";
import slide28 from "../images/outsideTable.jpg";
import slide29 from "../images/dinghy.jpg";
import FancyCarousel from "../components/FancyCarousel";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { MdAnchor } from "react-icons/md";
import card1 from "../images/card1.jpg";

function Explore() {
  const slides = [
    { id: 1, image: slide1 },
    { id: 2, image: slide2 },
    { id: 3, image: slide3 },
    { id: 4, image: slide4 },
    { id: 5, image: slide5 },
    { id: 6, image: slide6 },
    { id: 7, image: slide7 },
    { id: 8, image: slide8 },
    { id: 9, image: slide9 },
    { id: 10, image: slide10 },
    { id: 11, image: slide11 },
    { id: 12, image: slide12 },
    { id: 13, image: slide13 },
    { id: 14, image: slide14 },
    { id: 15, image: slide15 },
    { id: 16, image: slide16 },
    { id: 17, image: slide17 },
    { id: 18, image: slide18 },
    { id: 19, image: slide19 },
    { id: 20, image: slide20 },
    { id: 21, image: slide21 },
    { id: 22, image: slide22 },
    { id: 23, image: slide23 },
    { id: 24, image: slide24 },
    { id: 25, image: slide25 },
    { id: 26, image: slide26 },
    { id: 27, image: slide27 },
    { id: 28, image: slide28 },
    { id: 29, image: slide29 },
  ];

  return (
    <>
      <div className="pageFrame p-3 mb-3">
        <div className="text-center">
          <h1 style={{ color: "#13a8da" }} className="display-5 d-inline">
            Explore{" "}
          </h1>
          <h1 style={{ color: "#1e2e3d" }} className="display-5 d-inline">
            Our Vessel
          </h1>
          <h3 className="mt-3 mb-4 px-0 px-lg-5">
            CHASING WATERFALLS is “Luxury at its Finest” and is equipped with:
          </h3>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-5 col-lg-3">
            <img src={card1} alt="boat" className="w-100 rounded-2" />
          </div>
          <div className="col-12 col-md-8 col-lg-6 d-flex justify-content-center mt-4 mt-lg-0">
            <ul className="mb-0 fs-5 text-start">
              <li>Kayak</li>
              <li>SUP</li>
              <li>Surfboard</li>
              <li>Kit Surfing Equipment</li>
              <li>12-foot Highfield Fast Dinghy with Euro Helm</li>
              <li>Inflatables for towing behind dinghy </li>
              <li>Wakeboard</li>
              <li>Full Galley including full size oven, induction range…</li>
              <li>Large Home Style Freezer</li>
              <li>4 Cabins, 3 Heads - sleeps up to 6 guests comfortably</li>
              <li>Accommodations for up to 12 on a day charter</li>
              <li>Theater System</li>
              <li>
                Trampoline, Pulpits and Bow Sprit for fun hanging out areas
              </li>
              <li>
                Flybridge - huge area to hang out, see all there is to see from
                a higher angle
              </li>
              <li>
                State of the Art Navigation, Fish finder, radar, AIS, Iridium Go
              </li>
              <li>
                Huge Lithium Battery Set - makes getting off the grid easier.
              </li>
              <li>Water Maker - 36 gallons per hour</li>
              <li>Deck Shower</li>
              <li>
                Large Generator - this provides unlimited power for our long
                term charter guests
              </li>
              <li>Solar Generation - 2500 watts of Green Power</li>
              <li>Fishing Equipment, Rods, Reels, Lures and skill!</li>
              <li>Diving Compressor and tanks</li>
              <li>The list goes on…</li>
            </ul>
          </div>
          <div className="col-12 col-md-5 col-lg-3 mt-4 mt-lg-0">
            <img src={slide2} alt="kitchen" className="w-100 rounded-2" />
          </div>
        </div>
        <div className="text-center">
          <Button
            style={{
              border: "2px solid white",
              borderRadius: "25px",
              boxShadow: "0 3px 5px black",
            }}
            as={Link}
            to="/book"
            size="lg"
            className="mt-4"
          >
            <MdAnchor /> BOOK NOW
          </Button>
        </div>
      </div>
      <div className="pageFrame p-3 mt-0 mb-3">
        <FancyCarousel slides={slides} />
      </div>
    </>
  );
}

export default Explore;
