import FancyCarousel from "../components/FancyCarousel";
import React from "react";
import slide1 from "../images/slide (1).jpg";
import slide2 from "../images/slide (2).jpg";
import slide3 from "../images/slide (3).jpg";
import slide4 from "../images/slide (4).jpg";
import slide5 from "../images/slide (5).jpg";
import slide6 from "../images/slide (6).jpg";
import slide7 from "../images/slide (7).jpg";
import slide8 from "../images/slide (8).jpg";
import slide9 from "../images/slide (9).jpg";
import slide10 from "../images/slide (10).jpg";
import slide11 from "../images/card1.jpg";
import slide12 from "../images/card2.jpeg";
import slide13 from "../images/jump1.jpg";
import slide14 from "../images/jump2.jpg";
import slide15 from "../images/manta.jpg";
import slide16 from "../images/birdsEye.jpg";
import slide17 from "../images/boatView.jpg";
import slide18 from "../images/fish.jpg";
import slide19 from "../images/fish2.jpg";
import slide20 from "../images/haiti.jpg";
import slide21 from "../images/kids.jpg";
import slide22 from "../images/message.jpg";
import slide23 from "../images/relax.jpg";

import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { MdAnchor } from "react-icons/md";
import Video from "../components/Video";

function Home() {
  const slides = [
    { id: 1, image: slide1 },
    { id: 2, image: slide2 },
    { id: 3, image: slide3 },
    { id: 4, image: slide4 },
    { id: 5, image: slide5 },
    { id: 6, image: slide6 },
    { id: 7, image: slide7 },
    { id: 8, image: slide8 },
    { id: 9, image: slide9 },
    { id: 10, image: slide10 },
    { id: 11, image: slide11 },
    { id: 12, image: slide12 },
    { id: 13, image: slide13 },
    { id: 14, image: slide14 },
    { id: 15, image: slide15 },
    { id: 16, image: slide16 },
    { id: 17, image: slide17 },
    { id: 18, image: slide18 },
    { id: 19, image: slide19 },
    { id: 20, image: slide20 },
    { id: 21, image: slide21 },
    { id: 22, image: slide22 },
    { id: 23, image: slide23 },
  ];

  return (
    <>
      <div className="pageFrame p-3 mb-3">
        <div className="position-relative">
          <Video youtube="9Dm1OOcIXpE" />
        </div>
        <div className="mt-3 mb-4 fs-5 px-0 px-lg-5">
          <h1 style={{ color: "#13a8da" }} className="display-5 d-inline">
            Welcome to{" "}
          </h1>
          <h1 style={{ color: "#1e2e3d" }} className="display-5 d-inline">
            Chasing Waterfalls
          </h1>
          , the epitome of luxury yacht charters for those who expect nothing
          but the best. Our esteemed company is renowned for offering
          unparalleled sailing experiences, featuring an array of exclusive day
          charters departing from Honolulu and customized week-long charters
          that take you on a breathtaking journey through the Hawaiian Islands
          and beyond.
          <div style={{ height: "1rem" }} />
          At Chasing Waterfalls, we pride ourselves on delivering the utmost in
          personalized service, tailored to the unique needs and desires of our
          discerning clientele. Our curated experiences range from snorkeling,
          sightseeing, and savoring the magnificent sunsets of Hawaii to
          sophisticated bachelor and bachelorette parties, lavish weddings and
          engagements, cinematic film productions, and unforgettable week-long
          tours filled with island hopping and sun-drenched adventures.
          <div style={{ height: "1rem" }} />
          Our exceptional services go above and beyond sailing to provide you
          with a complete luxury experience. From professional-grade drone and
          photography services to exhilarating kite surfing and hydrofoil
          lessons, our premium offerings are designed to exceed your every
          expectation.
          <div style={{ height: "1rem" }} />
          At Chasing Waterfalls, we are committed to delivering a premium
          experience that is nothing short of extraordinary. Our team of experts
          works tirelessly to ensure that every detail is flawless, from the
          impeccable service to the stunning surroundings. Thank you for
          considering us for your luxury yacht charter needs, and we look
          forward to providing you with a world-class experience that will
          exceed all your expectations.
        </div>
        <div className="text-center">
          <Button
            style={{
              border: "2px solid white",
              borderRadius: "25px",
              boxShadow: "0 3px 5px black",
            }}
            as={Link}
            to="/book"
            size="lg"
          >
            <MdAnchor /> BOOK NOW
          </Button>
        </div>
      </div>
      <div className="pageFrame p-3 mt-0 mb-3">
        <FancyCarousel slides={slides} />
      </div>
    </>
  );
}

export default Home;
